import './Login.css';
import React from 'react'
import PasswordChecklist from "react-password-checklist"
import {ServerIntf} from './ServerIntf'

class PasswordReset extends React.Component {
    constructor() {
        super();

        this.state = {
            input: {
                username: '',
                password0: '',
                password1: '',
            },
            valid: false,
        };

        this.inputUpdate = this.inputUpdate.bind(this);
        this.submit = this.submit.bind(this);
    }

    inputUpdate(pare) {
        let input = this.state.input;

        this.setState({
            input: input,
            valid: pare
        });
    }

    submit(event) {
        event.preventDefault();
        if (this.state.valid) {
            let input = this.state.input;
            ServerIntf.ResetPassword(input.username, input.password0);

            input = {
                username: '',
                password0: '',
                password1: '',
            };

            this.setState({ input: input, valid: false });
        } else {
            ServerIntf.ParseError(new Error('Mismatched passwords'), 1000);
        }
    }

    render () {
        return (
            <div className="container">
                <div className="form-box">
                    <div className="header-form ">
                        <span className="login-header text-center">Welcome to Grab Bag Bingo Casino Management Tool</span>
                    </div>
                    <div className="header-form">
                        <h4 className="text-primary text-center">
                            <i className="fa fa-user-circle" style={{fontSize: "110px"}}></i>
                        </h4>
                        <div className="image"></div>
                    </div>
                    <div className="body-form">
                        <form onKeyDown={(e) => {
                            if (e.key === "Enter" && this.input.valid) {
                                this.submit()
                            }
                        }}>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fa fa-user"></i></span>
                                </div>
                                <input name="username" type="text" className="form-control" placeholder="Username"
                                       value={this.state.input.username}
                                       onChange={(e) => {
                                           this.state.input.username = e.target.value
                                       }}/>
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fa fa-lock"></i></span>
                                </div>
                                <input name="password0" type="password" className="form-control" placeholder="Password"
                                       value={this.state.input.password0}
                                       onChange={(e) => {
                                           this.state.input.password0 = e.target.value
                                       }}/>
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text"><i className="fa fa-lock"></i></span>
                                </div>
                                <input name="password1" type="password" className="form-control"
                                       placeholder="Confirm Password" value={this.state.input.password1}
                                       onChange={(e) => {
                                           this.state.input.password1 = e.target.value
                                       }}/>
                            </div>
                            <div className="flex-sm-row">
                                <button type="button" className="btn btn-secondary btn-block"
                                        disabled={!this.state.valid}
                                        onClick={(e) => this.submit(e)}>
                                    Register
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <PasswordChecklist
                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                    minLength={5}
                    value={this.state.input.password0}
                    valueAgain={this.state.input.password1}
                    onChange={(isValid) => {
                        this.inputUpdate(isValid)
                    }}
                />
            </div>
        )
    };
}

export default PasswordReset;