import { useEffect } from "react";
import {Col, Container, Row} from 'react-bootstrap';

const BingoCard = (props) => {
    const pubUrl = process.env.PUBLIC_URL;

    const animateBingoCard = (delay = 250) => {
        const cardRows = document.getElementsByClassName("bingo-card-cont");

        for (const row of cardRows) {
            let stars = row.getElementsByClassName("spot-star");

            for (let i = 0; i < stars.length; i++) {
                let index = parseInt(stars[i].name);
                setTimeout(() => {
                    if (index >= 0)
                        stars[i].style.visibility = "visible";
                }, delay * parseInt(stars[i].name));
            }
        }
    };

    const animatePrizes = (delay = 1250) => {
        const prizeRows = document.getElementsByClassName("prize-row");

        setTimeout(() => {
            for (let i = 0; i < prizeRows.length; i++) {
                let child0 = prizeRows[i].children[0];
                let child1 = prizeRows[i].children[1];
                if (child0.name === "1")
                    child0.style.visibility = "visible";
                if (child1.name === "1")
                    child1.style.visibility = "visible";
            }
        }, delay);
    };

    useEffect(() => {
        animateBingoCard();
        animatePrizes();
    });

    return (
        <div className="bingo-card-cont">
            <Container className="bingo-spot-row">
                <img src={pubUrl + "/bingo-assets/bingo-card.svg"} width="100%" className="bingo-card-img"/>
                <Row xs={5} md={5} lg={5} className="card-spot-row">
                    {props.game.CardSpots.split(",").map(function (item) {
                        if (!item)
                            return;
                        let hits = props.game.SpotsHit.split(",");
                        if (RegExp(/^\d+$/).test(item)) {
                            return (
                                <Col>
                                    <img src={pubUrl + "/bingo-assets/bingo-star.svg"} className="spot-star"
                                         name={hits.indexOf(item)}/>
                                    <h4 className="spot-txt">{item}</h4>
                                </Col>
                            );
                        } else {
                            return (
                                <Col>
                                    <img src={pubUrl + "/bingo-assets/bingo-star.svg"} className="spot-star"
                                         name={hits.indexOf(item)}/>
                                    <img
                                        src={item.includes("G") ? pubUrl + "/bingo-assets/god.svg" : pubUrl + "/bingo-assets/constellation.svg"}
                                        className="spot-symbol"/>
                                </Col>
                            );
                        }
                    })}
                </Row>
            </Container>
            <Row xs={2} md={2} lg={2} className="prize-row">
                <img src={pubUrl + "/bingo-assets/prize-bag.svg"} className="interim-prize"
                     name={props.game.InterimPrize === 3 ? 1 : 0}/>
                <img src={pubUrl + "/bingo-assets/crown.svg"} className="end-prize" name={props.game.EndPrize ? 1 : 0}/>
            </Row>
        </div>
    );
}

export default BingoCard;